import "./ReviewsPanelPage.css";
import {useEffect, useState} from "react";
import {usePsalmContext} from "../../../context/psalmContext/context";
import {removeDuplicates} from '../../../utils/utils'
import ProductCardComponent from "./ProductCardComponent";

const langs = [{"lang": "BG", "country": "Bulgaria"},
    {"lang": "EN", "country": "United Kingdom"},
    {"lang": "SI", "country": "Slovenia"},
    {"lang": "HR", "country": "Croatia"},
    {"lang": "IT", "country": "Italy"},
    {"lang": "CZ", "country": "Czech Republic"},
    {"lang": "SK", "country": "Slovakia"},
    {"lang": "HU", "country": "Hungary"},
    {"lang": "PT", "country": "Portugal"},
    {"lang": "ES", "country": "Spain"},
    {"lang": "PL", "country": "Poland"},
    {"lang": "DE", "country": "Germany"},
    {"lang": "AT", "country": "Austria"},
    {"lang": "CH", "country": "Switzerland"},
    {"lang": "LU", "country": "Luxembourg"},
    {"lang": "FR", "country": "France"},
    {"lang": "LT", "country": "Lithuania"},
    {"lang": "LV", "country": "Latvia"},
    {"lang": "EST", "country": "Estonia"},
    {"lang": "NL", "country": "The Netherlands"},
    {"lang": "BE", "country": "Belgium"},
    {"lang": "RO", "country": "Romania"},
    {"lang": "DK", "country": "Denmark"},
    {"lang": "SE", "country": "Sweden"},
    {"lang": "GR", "country": "Greece"}]
const ReviewsPanelPage = () => {

    const {
        personNames, reviewsList,
        getReviewPanelData,
        submitPersonalNames,
        deletePersonalName,
        updateProductReviews,
        bulkUpdateProductReviews
    } = usePsalmContext()
    const [brandName, setBrandName] = useState('none')
    const [lang, setLang] = useState('')
    const [activeTab, setActiveTab] = useState('')
    const [newInterval, setNewInterval] = useState(3)
    const [selectedProds, setSelectedProds] = useState([])

    useEffect(() => {
        getReviewPanelData(brandName)
    }, [brandName]);
    useEffect(() => {
        setSelectedProds([])
    }, [lang]);
    useEffect(() => {
        let newWeight = 0
        reviewsList.forEach(r => newWeight += r.weight)

    }, [reviewsList])
    const [namesArea, setNamesArea] = useState('')
    const storeNamesFunc = () => {
        submitPersonalNames(namesArea.split('\n').map(a => a.trim()).filter(a => a), lang)
        setNamesArea('')
    }
    const deleteNameFunc = (е) => {
        deletePersonalName(е.target.getAttribute('data-name-id'))
    }

    const selectAllFunc = () => {
        setSelectedProds(reviewsList.filter(a => a.lang === lang).map(a => a._id))
    }
    const selectNoTextFunc = () => {
        setSelectedProds(reviewsList
            .filter(a => a.lang === lang)
            .filter(a => a.texts.filter(b => !a.usedTexts.includes(b)).length === 0)
            .map(a => a._id))
    }
    const updateManyIntervalsFunc = () => {

        bulkUpdateProductReviews({_id: {$in: selectedProds}}, {weight: newInterval})
        setSelectedProds([])

    }
    const toggleManyProducts = (active) => {

        bulkUpdateProductReviews({_id: {$in: selectedProds}}, {active})
        setSelectedProds([])

    }
    const [reviewsArea, setReviewsArea] = useState('')
    const [productName, setProductName] = useState('')
    const [productUrl, setProductUrl] = useState('')
    let filteredReviews = reviewsList.filter(a => a.lang === lang).sort((b, a) => a.timestamp - b.timestamp)
    let hasDupe = reviewsList.some(fr => fr.productUrl === productUrl)
    const submitNewProductFunc = () => {
        let query = {productUrl}
        let texts = removeDuplicates(reviewsArea.split('\n').map(a=>a.trim()).filter(a => a))
        let update = {productName, productUrl, texts, lang}
        if (!hasDupe && productUrl && productName && texts.length > 0) {
            updateProductReviews(query, update)
            setProductUrl('')
            setProductName('')
            setReviewsArea('')
        }
    }
    let sidebarData = langs.map(lg => {
        let lNames = personNames.filter(pn => pn.lang === lg.lang)
        let products = reviewsList.filter(pr => pr.lang === lg.lang)
        return {
            ...lg,
            names: lNames.length,
            usedNames: lNames.filter(ln => ln.usedFor[0] === brandName).length,
            prods: products.length,
            inactiveProds: products.filter(a => a.texts.length - a.usedTexts.length === 0 || !a.active).length,
            texts: products.reduce((count, thisVal) => count + thisVal.texts.length, 0),
            usedTexts: products.reduce((count, thisVal) => count + thisVal.usedTexts.length, 0),
            pendingTasks: products.reduce((count, thisVal) => count + thisVal.pendingTasks || 0, 0)
        }
    })

    return <div className={'panelView'}>
        <div className={'sidebar'}>
            <select defaultValue={brandName} className={'socBoxSelect'} onChange={(e) => setBrandName(e.target.value)}>
                <option value={"none"} selected={true} disabled>Select Brand</option>
                <option value={'wowtea'}>WowTea</option>
                <option value={'uthin'}>UTHIN</option>
                <option value={'dr-d'}>Dr-D</option>
            </select>
            <select defaultValue={lang} className={'socBoxSelect'} onChange={(e) => setLang(e.target.value)}>
                <option selected={true} value={''} disabled={true}>Select Language</option>
                {langs
                    .sort((a, b) => a.lang.localeCompare(b.lang))
                    .map(lg => <option value={lg.lang} key={lg.lang}>{lg.lang} - {lg.country}</option>)}
            </select>
            {lang && <div className={`socBoxBtn sidebarBtn ${activeTab === 'names' ? "active" : ""}`}
                          onClick={_ => setActiveTab('names')}>Insert Names</div>}
            {lang && <div className={`socBoxBtn sidebarBtn ${activeTab === 'products' ? "active" : ""}`}
                          onClick={_ => setActiveTab('products')}>Manage Products and Reviews</div>}
<h3>Total: {sidebarData.reduce((sum,item)=>sum+item.usedTexts,0)} reviews written</h3>
            <div className={'remainingList'}>

                {sidebarData.sort((a, b) => ((a.names - a.usedNames) + a.prods + a.texts) - ((b.names - b.usedNames) + b.prods + b.texts))
                    .map(lg =>
                    <div key={lg.lang}>
                        <strong>{lg.lang}</strong>:
                        <span
                            className={(lg.names - lg.usedNames) ? ((lg.names - lg.usedNames) > 10 ? 'hasEnough' : "supplySoon") : "supplyMore"}>🧑 {lg.names - lg.usedNames} </span>
                        <span
                            className={lg.prods ? (lg.prods > 1 ? 'hasEnough' : "supplySoon") : "supplyMore"}>🛍️ {lg.prods} {!!lg.inactiveProds && <>(🔇{lg.inactiveProds})</>} </span>
                        <span
                            className={lg.texts ? (lg.texts > 10 ? 'hasEnough' : "supplySoon") : "supplyMore"}>💬 {lg.texts - lg.usedTexts} (✓{lg.usedTexts} ⌛{lg.pendingTasks})  </span>
                    </div>)}
            </div>

        </div>
        <div className={'dataContainer'}>

            {activeTab === 'names' && <div>
                <textarea className={'socBoxTextarea fullWidth'} rows={15} value={namesArea}
                          placeholder={'each name on new line'}
                          onChange={(e) => setNamesArea(e.target.value)}></textarea>
                <div className={'socBoxBtn'} onClick={storeNamesFunc}> Store Names</div>
                <ul>
                    {personNames.filter(a => a.lang === lang).map(pn => <li key={pn._id}
                                                                            style={{background: pn.usedFor.includes(brandName) ? 'orange' : ""}}> {pn.fullName}
                        <span onDoubleClick={deleteNameFunc} data-name-id={pn._id}> ❌ </span></li>)}
                </ul>
            </div>}

            {activeTab === 'products' && <div>
                <input className={'socBoxInput fullWidth'} placeholder={'Product Name'} value={productName}
                       onChange={(e) => setProductName(e.target.value)}/>
                <input className={`socBoxInput fullWidth ${hasDupe ? "duplicate" : ""}`} placeholder={'Product Url'}
                       value={productUrl}
                       onChange={(e) => setProductUrl(e.target.value)}/>
                <textarea className={'socBoxTextarea fullWidth'} rows={15} value={reviewsArea}
                          style={{overflowX: "scroll", overflowWrap: 'normal', whiteSpace: "pre"}}
                          placeholder={'each review on a new line'}
                          onChange={(e) => setReviewsArea(e.target.value.replaceAll('"', "").replaceAll("'", ""))}></textarea>
                <div className={`socBoxBtn ${hasDupe ? "duplicate" : ""}`} onClick={submitNewProductFunc}>
                    Submit Product Url
                </div>
                <br/>
                {<div className={'bulkEdit'}>
                    <button className={'socBoxBtn'} onClick={selectAllFunc}> Select All {lang}</button>
                    <button className={'socBoxBtn'} onClick={selectNoTextFunc}> Select With No Texts</button>
                    <button className={'socBoxBtn'} onClick={() => setSelectedProds([])}> Clear Selection</button>
                    {!!selectedProds.length && <>{" "}Set new interval: <strong
                        style={{color: "cyan"}}>{" once every ~"}
                        <input className={'weightInput'}
                               value={newInterval}
                               onChange={e => setNewInterval(Number(e.target.value))}
                               type={'number'}/> <small>days</small></strong> {" "}
                        <button className={'socBoxBtn'} onClick={updateManyIntervalsFunc}> Update Selected</button>
                        <button className={'socBoxBtn'} onClick={() => toggleManyProducts(false)}>⏸</button>
                        <button className={'socBoxBtn'} onClick={() => toggleManyProducts(true)}> ▶</button>
                    </>}
                </div>}
                <br/>
                <div className={'productReviewsContainer'}>
                    {filteredReviews.map(pr => <ProductCardComponent selected={selectedProds.includes(pr._id)}
                                                                     key={`${pr._id}${pr.weight}`} {...pr}
                                                                     isDuplicate={productUrl === pr.productUrl}
                                                                     setIsSelected={e => {
                                                                         if (!e.target.checked) setSelectedProds(selectedProds.filter(sp => sp !== pr._id))
                                                                         else {
                                                                             setSelectedProds([...selectedProds, pr._id])
                                                                         }
                                                                     }}/>)}
                </div>
            </div>}

        </div>
    </div>;
};
export default ReviewsPanelPage;
